
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '28'
    },
    grade: {
      type: String,
      default: 'basic'
    }
  }
})
