











import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import MainVisual from '@/components/MainVisual.vue'
import AboutBenefit from '@/components/AboutBenefit.vue'
import ReceiveCoupon from '@/components/ReceiveCoupon.vue'
import InviteFriend from '@/components/InviteFriend.vue'
import MainEvents from '@/components/MainEvents.vue'
import LuckyBoxFloatingButton from '@/components/LuckyBoxFloatingButton.vue'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    MainVisual,
    AboutBenefit,
    ReceiveCoupon,
    InviteFriend,
    MainEvents,
    LuckyBoxFloatingButton
  },
  setup () {
    const state = reactive({
    }) as State

    return {
      ...toRefs(state)
    }
  }
})
