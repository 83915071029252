















import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { inviteCredits, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'

interface State {
  [key: string]: any;
}

const {
  getters
} = store

export default defineComponent({
  setup () {
    const { $t } = useI18n()
    const state = reactive({
      $t,
      toReadableNumber,
      inviteCredits,
      sourceCountryIso: getters['country/$currentSourceCountryCode'],
      sourceCurrencyIso: getters['country/$currentSourceCurrencyIso'],
      thumb: computed(() => {
        try {
          return require(`@/assets/images/thumb/invite-${state.sourceCountryIso.toLowerCase()}.svg`)
        } catch {
          return require('@/assets/images/thumb/invite.svg')
        }
      })
    }) as State

    const labels = computed(() => {
      return {
        mainTitle: $t('Benefit.title.InviteFriends.Reward2', { amount: toReadableNumber(inviteCredits(state.sourceCountryIso)), currency: ` ${state.sourceCurrencyIso}` }).value,
        subTitle: $t('Benefit.text.InviteFriends.Reward2').value
      }
    })
    return {
      ...toRefs(state),
      labels
    }
  }
})
