



























import { defineComponent, computed, reactive, toRefs } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'
import { sendToApp } from '@/helpers'
import { IS_LOGGING } from '@/data/constants'

import type { Benefits, Person } from '@/types'

interface State {
  [key: string]: any;
  benefits: Benefits;
}

const {
  state: {
    auth: {
      person
    }
  },
  getters
} = store

const getBenefits = async (pointer: State) => {
  const payload = {
    params: {
      pid: getters['auth/$getPid'],
      source_country: getters['country/$currentSourceCountryObject'].id,
      destination_country: getters['country/$currentDestinationCountryObject'].id
    }
  }
  const { data } = await axios.get('/api/person/benefit', payload)
  pointer.benefits = data
}

export default defineComponent({
  setup () {
    const labels = {
      sourceCountryName: getters['country/$currentSourceCountryObject'].name
    }

    const state = reactive({
      benefits: {} as Benefits,
      faqId: 298,
      dynamicPath: (path: string) => computed(() => {
        const purePath = path !== '/help/faq'
        if (purePath) return path
        return `${path}/detail?id=${state.faqId}`
      }),
      isInAppLink: (targetType: string) => {
        const inApps = {
          A1_ALL: 'verifications'
        } as const
        const isInAppLink = inApps[targetType as keyof typeof inApps]
        return isInAppLink
      },
      redirectUri: ({ box_redirect_url: redirectUri, name }: Benefits) => {
        if (state.isInAppLink(name)) return sendToApp(state.isInAppLink(name))
        const [, path] = redirectUri.split('.com')
        const [baseUri] = redirectUri.split(path)
        sendToApp('event', { baseUri, path: state.dynamicPath(path).value, title: getters['util/title'](path) })
      },
      labels,
      activeCoupon: computed(() => (person as Person).active_coupon)
    }) as State

    getBenefits(state)

    const eventMultiTracking = (item: Benefits) => {
      sendToApp('select_boxMessage', { box_msgType: item.name }, IS_LOGGING)
      state.redirectUri(item)
    }

    return {
      ...toRefs(state),
      location,
      sendToApp,
      eventMultiTracking
    }
  }
})
