




















































import { defineComponent, PropType } from '@vue/composition-api'
import couponItem from '@/mixins/couponItem.ts'
import store from '@/store'
import { useI18n } from 'vue-composable'
import { toReadableNumber } from '@/helpers'

import type { ICoupon } from '@/types'

export default defineComponent({
  props: {
    card: {
      type: Object as PropType<ICoupon>,
      default: () => ({})
    },
    index: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const { $t } = useI18n()

    const { dispatch } = store
    const {
      currentTabIndex,
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      couponBenefitLabel,
      couponType
    } = couponItem(props)

    const receiveCoupon = () => {
      dispatch('coupon/GET_COUPON', getCard.value)
      dispatch('coupon/refreshMyCoupons')
      dispatch('coupon/refreshCouponBook')
    }

    return {
      $t,
      emit,
      currentTabIndex,
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      toReadableNumber,
      couponBenefitLabel,
      couponType,
      receiveCoupon
    }
  }
})
