







import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  components: {
  },
  props: {
    stampCount: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const resource = computed(() => require(`@/assets/images/stamp${props.stampCount}.png`))
    return {
      resource
    }
  }
})
