




















































































import { defineComponent, reactive, toRefs, watch, computed } from '@vue/composition-api'
import RegisterCouponNumber from './RegisterCouponNumber.vue'
import BaseTabs from '@/components/utils/BaseTabs.vue'
import MainCoupon from '@/components/MainCoupon.vue'
import store from '@/store'
import router from '@/router'
import { filteredCardTypes, isInApp, sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

import type { ICoupon, IMyCoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    RegisterCouponNumber,
    BaseTabs,
    MainCoupon
  },
  setup () {
    const { $t } = useI18n()

    const {
      state: {
        coupon,
        modal
      },
      getters,
      commit,
      dispatch
    } = store
    const { $route } = router.app
    const $router = router

    const state = reactive({
      $t,
      showTest: true,
      showSlidingUpPanel: computed(() => modal.visibles.includes('couponDetails')),
      showRegisterCouponNumber: computed(() => modal.visibles.includes('registerCouponNumber')),
      currentTabIndex: computed(() => coupon.currentTabIndex.type),
      selectedCouponId: 0,
      useAll: true,
      loading: false,
      usableCoupons: computed(() => {
        return getters['coupon/myCouponByCountry'].filter((card: IMyCoupon) => {
          return filteredCardsByStatus(card)
        })
      }),
      receivableCoupons: [],
      filteredCards: computed(() => {
        const cards = (state.receivableCoupons as ICoupon[]).filter(({ settlement_type }) => {
          const index = state.useAll ? 1 : 0
          return filteredCardTypes(coupon.currentTabIndex.type - index).includes(settlement_type)
        })
        return cards.slice(0, 3)
      }),
      isAndroid: computed(() => isInApp && /Android/i.test(window.navigator.userAgent)),
      additionalCouponCount: computed(() => getters['coupon/couponsByCountry'].length),
      selectedCoupon: computed(() => {
        const [filterData] = (state.filteredCards as ICoupon[]).filter(({ id }) => id === state.selectedCouponId)
        return { card: filterData }
      })
    }) as State

    const filteredCardsByStatus = (card: IMyCoupon) => {
      const status = {
        available: 1,
        completed: 2
      }
      const couponStatusfilters = {
        completed: card.status === status.completed,
        expired: card.status !== status.completed &&
          new Date(card.expiration_date).getTime() > 0 &&
          (new Date(card.expiration_date).getTime() <= new Date().getTime())
      }
      return !couponStatusfilters.completed && !couponStatusfilters.expired
    }

    const toggleSlidingUpPanel = (id: number) => {
      const isOpened = state.showSlidingUpPanel
      if (isOpened) return commit('modal/$CLOSE_MODAL')
      state.selectedCouponId = id
      commit('modal/$OPEN_MODAL', { modalName: 'couponDetails', callback: state.selectedCoupon, showBackdropInApp: false })
    }

    const clickMoreCouponBooks = () => {
      sendToApp('go_gettingCoupons', {}, IS_LOGGING)
      redirectCouponBook()
    }

    const clickMyCoupon = () => {
      sendToApp('select_gotoMyCouponBox', {}, IS_LOGGING)
      sendToApp('myCoupon', { baseUri: location.origin, path: '/my-coupon', title: $t('Benefit.button.CouponBox.none').value })
    }

    const redirectCouponBook = () => {
      const callback = {
        message: $t('Benefit.guide.FormoreCoupons.CompleteVerification').value,
        button: $t('Global.button.Verify.None').value,
        action: {
          name: sendToApp,
          type: 'verifications'
        }
      }
      if (!getters['auth/isVerifyCompleteStatus']) return commit('modal/$OPEN_MODAL', { modalName: 'warning', callback })
      sendToApp('couponBook', { baseUri: location.origin, path: '/coupon-book', title: $t('Benefit.button.ReceivingCoupons.None').value })
    }

    const toggleRegisterCouponNumber = () => {
      const isOpened = state.showRegisterCouponNumber
      if (isOpened) return commit('modal/$CLOSE_MODAL')
      commit('modal/$OPEN_MODAL', { modalName: 'registerCouponNumber', showBackdropInApp: false })
    }

    const handleShowTest = () => {
      state.showTest = true
    }

    const changeTabs = (index: number) => {
      commit('$SET_STATE', { path: 'coupon.currentTabIndex.type', value: index })
    }

    const setCurrencyId = () => {
      $router.replace({ path: $route.path, query: Object.assign({}, $route.query, { currency: state.sourceCurrencyId }) })
    }

    const getCouponCards = async (toCurrencyId: string | (string | null)[]) => {
      state.loading = true
      const changableCurrencyId = !$route.query.currency || (toCurrencyId !== $route.query.currency)
      changableCurrencyId && setCurrencyId()
      await dispatch('coupon/refreshMyCoupons')
      await dispatch('coupon/refreshCouponBook')
      state.loading = false
    }

    const getReceivableCoupons = () => {
      state.receivableCoupons = [...getters['coupon/couponsByCountry'].filter((coupon: ICoupon) => !state.usableCoupons.map((coupon: IMyCoupon) => coupon.coupon_id).includes(coupon.id))]
    }

    $route.query.coupon_code && toggleRegisterCouponNumber()

    watch(
      () => $route.query.currency,
      (toCurrencyId) => {
        getCouponCards(toCurrencyId)
      },
      { immediate: true }
    )

    watch(
      () => state.loading,
      (loading) => {
        !loading && getReceivableCoupons()
      }
    )

    return {
      ...toRefs(state),
      handleShowTest,
      location,
      sendToApp,
      clickMoreCouponBooks,
      clickMyCoupon,
      redirectCouponBook,
      toggleSlidingUpPanel,
      toggleRegisterCouponNumber,
      changeTabs
    }
  }
})
