var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receive-coupon"},[_c('div',{staticClass:"receive-coupon__header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Benefit.button.ReceivingCoupons.None').value))]),_c('button',{staticClass:"add-button",on:{"click":_vm.toggleRegisterCouponNumber}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}}),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.$t('Benefit.button.RegisterCouponCode.none').value))])])]),_c('BaseTabs',{attrs:{"shape":"round","items":[
      _vm.$t('Benefit.chip.FeeDiscount.none').value,
      _vm.$t('Benefit.chip.receivingcash.none').value,
      _vm.$t('Benefit.chip.gettingluckybox.none').value
    ],"use-all":_vm.useAll},on:{"change":_vm.changeTabs}}),(_vm.filteredCards.length)?[_c('div',{staticClass:"receive-coupon__cards"},[_c('ul',{staticClass:"cards"},[_vm._l((_vm.filteredCards),function(card,index){return _c('MainCoupon',{key:index,attrs:{"card":card,"index":index},on:{"toggleSlidingUpPanel":_vm.toggleSlidingUpPanel}})}),_c('li',{staticClass:"item"},[_c('button',{staticClass:"show-all",on:{"click":_vm.redirectCouponBook}},[_vm._v(" "+_vm._s(_vm.$t('Benefit.button.SeeAll.None').value)+" "),_c('b-icon-chevron-right',{staticClass:"align-middle",attrs:{"font-scale":".8"}})],1)])],2)])]:[_c('div',{staticClass:"receive-coupon__empty"},[_c('img',{staticClass:"coupon-splash",attrs:{"srcset":require("@/assets/images/thumb/gray-coupon-1x.png") + " 1x, " + require("@/assets/images/thumb/gray-coupon-2x.png") + " 2x, " + require("@/assets/images/thumb/gray-coupon-3x.png") + " 3x","alt":""}}),_c('p',{staticClass:"pre-line description"},[_vm._v(" "+_vm._s(_vm.$t('Benefit.guide.NoMoreCoupons.Receive').value)+" ")]),_c('button',{on:{"click":_vm.clickMyCoupon}},[_vm._v(" "+_vm._s(_vm.$t('Benefit.button.GoCouponbox.none').value)+" ")])])],_c('div',{staticClass:"receive-coupon__button"},[_c('button',{on:{"click":_vm.clickMoreCouponBooks}},[_vm._v(" "+_vm._s(_vm.$t('Benefit.button.MoreCoupons.none').value)+" "),_c('span',[_vm._v(" ("+_vm._s(_vm.$t('Benefit.text.Numbers.none', { number: _vm.additionalCouponCount }).value)+")")]),_c('b-icon-arrow-right',{staticClass:"align-middle",attrs:{"font-scale":"1"}})],1)]),_c('RegisterCouponNumber',{class:{
      'is-android': _vm.isAndroid,
      'is-opened': _vm.showRegisterCouponNumber
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }