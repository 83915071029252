





































import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { getDateTime, sendToApp } from '@/helpers'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

import type { Event } from '@/types'

interface State {
  [key: string]: any;
  list: Event[];
}

const redirectType = {
  URL: 1,
  DETAIL: 2
}

export default defineComponent({
  components: {
  },
  setup () {
    const { $t } = useI18n()

    const { $route } = router.app

    const {
      getters
    } = store

    const labels = {
      sourceCountryName: getters['country/$currentSourceCountryObject'].name,
      destinationCountryName: getters['country/$currentDestinationCountryObject'].name
    }

    const state = reactive({
      sentbeDomain: process.env.VUE_APP_SENTBE_DOMAIN,
      $t,
      list: [],
      filteredListByCountry: computed(() => {
        const { id: sourceId } = getters['country/$currentSourceCountryObject']
        const { id: destinationId } = getters['country/$currentDestinationCountryObject']
        const filtered = state.list.filter(({ source_countries: sourceCountries, content_countries: destinationCountries }) => {
          const ALL = 251
          const useFilter = {
            source: sourceCountries.includes(ALL) ||
              (!sourceId || sourceCountries.includes(sourceId)),
            destination: destinationCountries.includes(ALL) ||
              (!destinationId || destinationCountries.includes(destinationId))
          }
          return useFilter.source && useFilter.destination
        }).slice(0, 3)
        return filtered
      }),
      labels,
      isEndedEvent ({ end_date }: Event) {
        return new Date(end_date) < new Date()
      },
      getEventPeriod ({ start_date, end_date }: Event) {
        if (state.isEndedEvent({ end_date })) return $t('Global.All.Event.End').value
        return `${getDateTime(start_date, false, {})} - ${getDateTime(end_date, false, {})}`
      },
      eventDetailParams: ({ id = 0, redirect_url: url = '', redirect_type: type = 0 }) => {
        const title = $t('Global.label.event.blank').value
        const isSelfEvent = url.includes(process.env.VUE_APP_SENTBE_DOMAIN as string)
        if (!isSelfEvent) return { baseUri: url, title }
        const fullPath = url.slice(`${process.env.VUE_APP_SENTBE_DOMAIN}/${$route.params.lang}`.length)
        let [path] = fullPath.split('?')
        const hasCustomDetail = type === redirectType.DETAIL
        hasCustomDetail && (path = `/help/event/detail?detail_id=${id}`)
        return { baseUri: process.env.VUE_APP_SENTBE_DOMAIN, path, title }
      }
    }) as State

    const ascendingList = (list: Event[] = []) => {
      if (!list.length) return []
      const events = [...list].sort((a, b) => {
        const isSameDisableState = state.isEndedEvent(a) === state.isEndedEvent(b)
        if (isSameDisableState) return a.order - b.order
        return state.isEndedEvent(a) ? 1 : -1
      })
      return events
    }

    const setEvent = (list: Event[]) => {
      state.list = ascendingList(list)
    }

    const getEvents = async () => {
      const { id: sourceId } = getters['country/$currentSourceCountryObject']
      const { data: { list } } = await axios.post('/api/events', { source_country: sourceId })
      const startedEvents = (list as Event[]).filter(({ start_date: startDate }) => new Date(startDate) < new Date())
      setEvent(startedEvents)
    }

    const clickEvent = (item: Event) => {
      sendToApp('select_eventListReward', { event_id: item.id }, IS_LOGGING)
      sendToApp('event', state.eventDetailParams(item))
    }

    const useAmplitudeLogging = () => {
      const amplitudeProperties = {
        sending_country: getters['country/$currentSourceCountryCode'],
        receiving_country: getters['country/$currentDestinationCountryCode']
      }
      sendToApp('view_eventListReward', amplitudeProperties, IS_LOGGING)
    }

    const clickAllEvents = () => {
      sendToApp('select_seeAllEvents', {}, IS_LOGGING)
      sendToApp('event', { baseUri: state.sentbeDomain, path: '/help/event', title: $t('Global.label.event.blank').value })
    }

    getEvents()
    useAmplitudeLogging()

    return {
      ...toRefs(state),
      clickEvent,
      clickAllEvents
    }
  }
})
