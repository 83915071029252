






















































import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import InviteSnsChannels from '@/components/InviteSnsChannels.vue'
import InviteVisual from '@/components/InviteVisual.vue'
import StampPlate from '@/components/utils/StampPlate.vue'
import { inviteCredits, sendToApp, toReadableNumber } from '@/helpers'
import store from '@/store'
import axios from 'axios'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    InviteSnsChannels,
    InviteVisual,
    StampPlate
  },
  setup () {
    const { $t } = useI18n()
    const getInvites = async () => {
      const { data: value } = await axios.post('/api/invite/page', { pid: store.getters['auth/$getPid'] })
      store.commit('$SET_STATE', { path: 'auth.invites', value })
    }
    getInvites()
    const {
      state: {
        auth
      },
      getters
    } = store

    const state = reactive({
      $t,
      auth,
      hasInviteeCount: computed(() => !!auth.invites.unverified_invitee_count || !!auth.invites.invitee_count),
      inviteCredits,
      sourceCountryIso: getters['country/$currentSourceCountryCode'],
      sourceCurrencyIso: getters['country/$currentSourceCurrencyIso'],
      toReadableNumber,
      thumb: computed(() => {
        try {
          return require(`@/assets/images/thumb/invite-${state.sourceCountryIso.toLowerCase()}.svg`)
        } catch {
          return require('@/assets/images/thumb/invite-kr.svg')
        }
      })
    }) as State

    const clickInviteFriend = () => {
      sendToApp('start_myFriends', {}, IS_LOGGING)
      sendToApp('inviteFriend', { baseUri: location.origin, path: '/invite-friend', title: $t('Benefit.title.InviteFriendsEvent.none').value })
    }

    return {
      ...toRefs(state),
      location,
      clickInviteFriend
    }
  }
})
