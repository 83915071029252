


























































import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { toCapitalize, toReadableNumber } from '@/helpers/utils'
import store from '@/store'
import { sendToApp } from '@/helpers'
import type { Person } from '@/types'
import { useI18n } from 'vue-composable'
import IconGrade from '@/components/utils/IconGrade.vue'
import BigNumber from 'bignumber.js'
import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any
}

interface Benefit {
  name?: string
  type: string
  label: string
  amount: string
  tooltip: string
  require?: boolean
  callback?: {
    message: string
    button: string
    action: {
      name: void,
      type: string
    }
  }
  params?: {
    baseUri: string
    path: string
    title: string
  }
  amplitudeEventName?: string
}

export default defineComponent({
  components: { IconGrade },
  setup () {
    const { $t } = useI18n()

    const {
      state: {
        auth: {
          person
        }
      },
      getters,
      commit
    } = store

    const state = reactive({
      $t,
      name: computed(() => (person as Person).name.first),
      activeCoupon: computed(() => (person as Person).active_coupon),
      activeLuckyBox: computed(() => (person as Person).active_lucky_box),
      grade: computed(() => (person as Person).grade),
      credit: computed(() => (person as Person).credit),
      wallet: computed(() => (person as Person).wallet),
      gradeLabel: computed(() => getters['auth/gradeLabel']),
      gradeBubbleLabel: computed(() => gradeBubble[state.grade][getters['country/$currentSourceCountryCode']] || gradeBubble[state.grade].default),
      toCapitalize,
      receivingBenefits: computed(() => {
        return [
          {
            name: 'myCoupon',
            type: 'coupon',
            label: $t('Benefit.button.CouponBox.none').value,
            amount: $t('Benefit.text.Numbers.none', { number: toReadableNumber(state.activeCoupon) }).value,
            tooltip: $t('Benefit.guide.Tooltip.MyCouponBox').value,
            params: {
              baseUri: location.origin,
              path: '/my-coupon',
              title: $t('Benefit.button.CouponBox.none').value
            },
            amplitudeEventName: 'select_myCoupon'
          }, {
            type: 'wallet',
            label: $t('Benefit.button.WalletCash.none').value,
            amount: `${toReadableNumber(state.wallet)} ${getters['country/$currentSourceCurrencyIso']}`,
            tooltip: $t('Benefit.guide.Tooltip.Wallet').value,
            require: getters['auth/isVerifyCompleteStatus'],
            callback: {
              message: $t('Benefit.guide.UseWallet.CompltetVerification').value,
              button: $t('Global.button.Verify.None').value,
              action: {
                name: sendToApp,
                type: 'verifications'
              }
            },
            amplitudeEventName: 'start_myWallet'
          }, {
            type: 'credit',
            label: $t('Benefit.button.Credit.none').value,
            amount: `${toReadableNumber(state.credit)}`,
            tooltip: $t('Benefit.guide.Tooltip.Credit').value,
            amplitudeEventName: 'select_myCredit'
          }, {
            name: 'luckyBox',
            type: 'luckybox',
            label: $t('Benefit.button.Luckybox.none').value,
            amount: $t('Benefit.text.Numbers.none', { number: toReadableNumber(state.activeLuckyBox) }).value,
            tooltip: $t('Benefit.guide.Tooltip.Luckybox').value,
            params: {
              baseUri: process.env.VUE_APP_SENTBE_DOMAIN,
              path: '/lucky_box',
              title: $t('Benefit.button.Luckybox.none').value
            },
            amplitudeEventName: 'select_myLuckybox'
          }
        ]
      }),
      redirectBenefits: (benefit: Benefit) => {
        benefit.amplitudeEventName && sendToApp(benefit.amplitudeEventName, {}, IS_LOGGING)
        const isValidAccess = benefit.require || benefit.require === undefined
        if (isValidAccess) return sendToApp(benefit.name || benefit.type, benefit.params)
        commit('modal/$OPEN_MODAL', { modalName: 'warning', callback: benefit.callback })
      }
    }) as State

    const { amount, number } = getters['country/$currentSourceCountryObject'].fee_discount.coupon[state.gradeLabel] || {}

    const gradeBubble = {
      0: {
        default: $t('Benefit.guide.GetmorefeeDiscounts.Basic').value
      },
      1: {
        KR: $t('Benefit.guide.FeeDiscount.KR', { percent: (new BigNumber(1).minus(person.grade_discount_rate)) * 100 }).value,
        default: $t('Benefit.guide.FeeDiscount.notKR', { amount, currency: getters['country/$currentSourceCurrencyIso'], number }).value
      },
      2: {
        KR: $t('Benefit.guide.FeeDiscount.KR', { percent: (new BigNumber(1).minus(person.grade_discount_rate)) * 100 }).value,
        default: $t('Benefit.guide.FeeDiscount.notKR', { amount, currency: getters['country/$currentSourceCurrencyIso'], number }).value
      }
    } as any

    const getResource = (fileName: string) => {
      return require(`@/assets/images/${fileName}.svg`)
    }

    const clickMyGrade = () => {
      sendToApp('select_myVip', {}, IS_LOGGING)
      sendToApp('myGrade', { baseUri: location.origin, path: '/my-grade', title: $t('Benefit.navigation.MyLevel.none').value })
    }

    return {
      ...toRefs(state),
      getResource,
      location,
      clickMyGrade
    }
  }
})
