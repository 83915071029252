















































import { defineComponent, computed, reactive, ref, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { useI18n, useOnOutsidePress } from 'vue-composable'
import { createDimEl, focusInput } from '@/helpers'

interface State {
  [key: string]: any;
  input: null | HTMLInputElement;
}

export default defineComponent({
  setup () {
    const { $t } = useI18n()

    const { $route } = router.app

    const {
      state: {
        modal,
        util
      },
      commit,
      dispatch
    } = store

    const state = reactive({
      $t,
      input: null,
      form: {
        coupon: ''
      },
      errorMessage: computed(() => util.inputMessage),
      showRegisterCouponNumber: computed(() => modal.visibles.includes('registerCouponNumber'))
    }) as State

    const popupEl = ref(null)

    const hideRegisterCouponNumber = () => {
      commit('modal/$CLOSE_MODAL')
    }

    const resetValidation = () => {
      commit('$SET_STATE', { path: 'util.inputMessage', value: '' })
    }

    const onSubmit = (event: Event) => {
      event.preventDefault()
      if (!state.form.coupon) return commit('$SET_STATE', { path: 'util.inputMessage', value: $t('Global.error.input.none').value })
      resetValidation()
      dispatch('coupon/GET_COUPON', { code: state.form.coupon })
    }

    $route.query.coupon_code && (state.form.coupon = $route.query.coupon_code)

    useOnOutsidePress(popupEl, () => {
      state.showRegisterCouponNumber && hideRegisterCouponNumber()
    })

    watch(
      () => state.showRegisterCouponNumber,
      () => {
        focusInput(state.showRegisterCouponNumber, state.input)
        resetValidation()
        createDimEl(state.showRegisterCouponNumber)
      }
    )
    return {
      ...toRefs(state),
      popupEl,
      onSubmit,
      hideRegisterCouponNumber
    }
  }
})
