














import { defineComponent } from '@vue/composition-api'
import { sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'

export default defineComponent({
  setup () {
    const { $t } = useI18n()
    return {
      $t,
      sentbeDomain: process.env.VUE_APP_SENTBE_DOMAIN,
      sendToApp
    }
  }
})
